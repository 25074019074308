






































  import { LAYOUTS, PRODUCTS } from '@/constant/Enums';
  import { UserModel } from '@/store/auth/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Watch } from 'vue-property-decorator';
  import { Action, Mutation, State } from 'vuex-class';

  @Component({
    name: 'NavBar',
    components: {},
    mixins: [],
  })
  export default class NavBar extends Vue {
    @Action('CheckuserAuth', { namespace: 'auth' }) CheckuserAuth!: () => Promise<boolean>;

    @State('user', { namespace: 'auth' }) user!: UserModel;

    @Mutation('set-layout') setLayout!: (layout: LAYOUTS) => void;
    @Mutation('set-user', {namespace: 'auth'}) setUser!: (user: UserModel) => void;

    @Watch('$route') onChangeRoute() {
      window.scrollTo(0, 0);
    }

    public LIST_PRODUCT = PRODUCTS;
    public isAuthenticated = false;

    public created(): void {
      this.CheckuserAuth().then((isLoggedin) => (this.isAuthenticated = isLoggedin));
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnAuth
     */
    public OnAuth(name: string) {
      this.setLayout(LAYOUTS.AUTHENTICATION);
      this.$router.push({ name });
    }

    public onClickLogout() {
      this.$cookies.remove("SES")
      this.$cookies.remove("PHPSESSID")
      this.setUser(null)
      this.$router.push({name: 'login'})
    }
  }
